import React, { useEffect, useRef, useState } from "react";
import { useAuth } from "../hooks/useAuth";
import style from "./ReportModal.module.css";
import { backendurl } from "../assests/globalVariable";

export default function ReportModal({
    onClose,
    schedules,
    reports,
    examid,
    loadReports,
}) {
    const { user } = useAuth();
    const [dates, setDates] = useState([]);
    const [selectedDate, setSelectedDate] = useState("");
    const [subjects, setSubjects] = useState([]);
    const [selectedSubject, setSelectedSubject] = useState("");
    const [number, setNumber] = useState([]);
    const [selectedNumber, setSelectedNumber] = useState("1");
    const [closing, setCloseing] = useState(false);
    const [selectedPage, setSelectedPage] = useState(0);
    const [unRead, setUnRead] = useState(false);
    const text = useRef(null);

    useEffect(() => {
        if (reports) {
            [...reports].forEach((r) => {
                if (r["notice"]) {
                    setUnRead(true);
                    return;
                }
            });
        }

        return () => {};
    }, [reports]);

    const clicked = () => {
        setCloseing(true);
        setTimeout(() => {
            onClose();
        }, 1000);
    };

    const handleSelectDateChange = (event) => {
        setSelectedDate(event.target.value);
    };
    const handleSelectSubjectChange = (event) => {
        setSelectedSubject(event.target.value);
    };
    const handleSelectNumberChange = (event) => {
        setSelectedNumber(event.target.value);
    };
    useEffect(() => {
        if (!schedules) {
            return;
        }

        let d = schedules.map((schedule) => schedule.date);
        setDates(d);
        setSelectedDate(d[0]);
        return () => {};
    }, [schedules]);

    useEffect(() => {
        if (!schedules) {
            return;
        }
        let d = [...schedules].find((s) => s.date === selectedDate);
        if (d) {
            setSubjects(d["subjects"]);
            setSelectedSubject(d["subjects"][0]["name"]);
        }

        return () => {};
    }, [selectedDate, schedules]);

    useEffect(() => {
        if (!schedules) {
            return;
        }
        let d = [...schedules].find((s) => s.date === selectedDate);
        if (d) {
            let d2 = d.subjects.find((s) => s.name === selectedSubject);
            if (d2 && d2.questions) {
                setNumber(d2.questions);
                setSelectedNumber("1");
            }
        }

        return () => {};
    }, [selectedDate, selectedSubject, schedules]);

    const sendReport = async () => {
        if (text.current.value === "") {
            alert("請輸入問題內容!!");
            return;
        }
        try {
            const response = await fetch(backendurl + "exam/makeReport", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    userid: user._id,
                    examid: examid,
                    date: selectedDate,
                    subject: selectedSubject,
                    number: selectedNumber,
                    report: text.current.value,
                }),
            });

            if (response.ok) {
                alert("問題已提交");
                loadReports();
                text.current.value = "";
                setCloseing(true);
                setTimeout(() => {
                    onClose();
                }, 1000);
            } else {
                const data = await response.json();
                alert(data.message);
            }
        } catch (error) {
            console.error("過程中發生錯誤", error);
        }
    };
    const removeReport = async (_id) => {
        try {
            const response = await fetch(backendurl + "backend/delReport", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    _id: _id,
                }),
            });

            if (response.ok) {
                alert("更新成功");
                loadReports();
            } else {
                const data = await response.json();
                alert(data.message);
            }
        } catch (error) {
            console.error("過程中發生錯誤", error);
        }
    };

    const downloadImage = (imageUrl) => {
        const link = document.createElement("a");
        link.href = imageUrl;
        link.download = "3DQuizPic.jpg";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <div
            className={`${style.backdrop} ${closing ? style.fadeout : null}`}
            onClick={clicked}
        >
            <div className={style.content} onClick={(e) => e.stopPropagation()}>
                <div className={style.changepage}>
                    <div
                        className={`${style.pagebt} `}
                        onClick={() => {
                            setSelectedPage(0);
                        }}
                    >
                        提交問題
                    </div>
                    <div
                        className={`${style.pagebt} ${
                            unRead ? style.withnotice : null
                        } `}
                        onClick={() => {
                            setSelectedPage(1);
                            setUnRead(false);
                        }}
                    >
                        查看回復
                    </div>
                    <div
                        className={`${style.underline} ${
                            selectedPage === 0 ? null : style.right
                        }`}
                    ></div>
                </div>
                <div
                    className={`${style.reportpage} ${
                        selectedPage === 0 ? style.show : style.notshow
                    }`}
                >
                    <div className={style.optionrow}>
                        <span className={style.optiontxt}> {"測驗日期: "}</span>
                        <select
                            name="date"
                            id="date"
                            value={selectedDate}
                            onChange={handleSelectDateChange}
                            className={style.select}
                        >
                            {[...dates].map((value, index) => (
                                <option key={index} value={value}>
                                    {value}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className={style.optionrow}>
                        <span className={style.optiontxt}>{"測驗科目: "}</span>
                        <select
                            name="subject"
                            id="subject"
                            value={selectedSubject}
                            onChange={handleSelectSubjectChange}
                            className={style.select}
                        >
                            {[...subjects].map((value, index) => (
                                <option key={index} value={value.name}>
                                    {value.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className={style.optionrow}>
                        <span className={style.optiontxt}>{"題號: "}</span>
                        <select
                            name="number"
                            id="number"
                            value={selectedNumber}
                            onChange={handleSelectNumberChange}
                            className={style.select}
                        >
                            {[...number].map((value, index) => (
                                <option key={index} value={index + 1}>
                                    {index + 1}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className={style.reportrow}>
                        <div className={style.optiontxt}>{"問題陳述: "}</div>
                        <textarea
                            className={style.reportinput}
                            type="text"
                            ref={text}
                        />
                    </div>
                    <button className={style.sendbt} onClick={sendReport}>
                        回報問題
                    </button>
                </div>
                <div
                    className={`${style.reportpage} ${
                        selectedPage === 1 ? style.show : style.notshow
                    }`}
                >
                    {reports && (
                        <div>
                            {[...reports].map((r) => {
                                return (
                                    <div
                                        key={r._id}
                                        className={style.onereport}
                                    >
                                        <span className={style.reporttitle}>
                                            日期:
                                        </span>
                                        {r.date}
                                        <br />
                                        <span className={style.reporttitle}>
                                            科目:
                                        </span>
                                        {r.subject}:{" " + parseInt(r.number)}
                                        <br />
                                        <span className={style.reporttitle}>
                                            問題:
                                        </span>
                                        {r.report}
                                        <br />
                                        <span className={style.reporttitle}>
                                            回覆:
                                        </span>
                                        {r.response}
                                        <br />
                                        {r.picture
                                            ? [...r.picture].map((p) => {
                                                  return (
                                                      <img
                                                          key={p}
                                                          onClick={() => {
                                                              downloadImage(
                                                                  backendurl +
                                                                      "reportPics/" +
                                                                      p
                                                              );
                                                          }}
                                                          className={
                                                              style.responsepic
                                                          }
                                                          alt=""
                                                          src={
                                                              backendurl +
                                                              "reportPics/" +
                                                              p
                                                          }
                                                      />
                                                  );
                                              })
                                            : ""}
                                        <button
                                            className={style.bt}
                                            onClick={() => {
                                                removeReport(r._id);
                                            }}
                                        >
                                            <svg
                                                width="2rem"
                                                height="2rem"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M8.00191 9.41621C7.61138 9.02569 7.61138 8.39252 8.00191 8.002C8.39243 7.61147 9.0256 7.61147 9.41612 8.002L12.0057 10.5916L14.5896 8.00771C14.9801 7.61719 15.6133 7.61719 16.0038 8.00771C16.3943 8.39824 16.3943 9.0314 16.0038 9.42193L13.4199 12.0058L16.0039 14.5897C16.3944 14.9803 16.3944 15.6134 16.0039 16.004C15.6133 16.3945 14.9802 16.3945 14.5896 16.004L12.0057 13.42L9.42192 16.0038C9.03139 16.3943 8.39823 16.3943 8.00771 16.0038C7.61718 15.6133 7.61718 14.9801 8.00771 14.5896L10.5915 12.0058L8.00191 9.41621Z"
                                                    fill="var(--fontcolor)"
                                                />
                                                <path
                                                    fillRule="evenodd"
                                                    clipRule="evenodd"
                                                    d="M23 4C23 2.34315 21.6569 1 20 1H4C2.34315 1 1 2.34315 1 4V20C1 21.6569 2.34315 23 4 23H20C21.6569 23 23 21.6569 23 20V4ZM21 4C21 3.44772 20.5523 3 20 3H4C3.44772 3 3 3.44772 3 4V20C3 20.5523 3.44772 21 4 21H20C20.5523 21 21 20.5523 21 20V4Z"
                                                    fill="var(--fontcolor)"
                                                />
                                            </svg>
                                        </button>
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </div>
                <button
                    className={style.bt}
                    onClick={() => {
                        clicked();
                    }}
                >
                    <svg
                        width="2rem"
                        height="2rem"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M8.00191 9.41621C7.61138 9.02569 7.61138 8.39252 8.00191 8.002C8.39243 7.61147 9.0256 7.61147 9.41612 8.002L12.0057 10.5916L14.5896 8.00771C14.9801 7.61719 15.6133 7.61719 16.0038 8.00771C16.3943 8.39824 16.3943 9.0314 16.0038 9.42193L13.4199 12.0058L16.0039 14.5897C16.3944 14.9803 16.3944 15.6134 16.0039 16.004C15.6133 16.3945 14.9802 16.3945 14.5896 16.004L12.0057 13.42L9.42192 16.0038C9.03139 16.3943 8.39823 16.3943 8.00771 16.0038C7.61718 15.6133 7.61718 14.9801 8.00771 14.5896L10.5915 12.0058L8.00191 9.41621Z"
                            fill="var(--fontcolor)"
                        />
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M23 4C23 2.34315 21.6569 1 20 1H4C2.34315 1 1 2.34315 1 4V20C1 21.6569 2.34315 23 4 23H20C21.6569 23 23 21.6569 23 20V4ZM21 4C21 3.44772 20.5523 3 20 3H4C3.44772 3 3 3.44772 3 4V20C3 20.5523 3.44772 21 4 21H20C20.5523 21 21 20.5523 21 20V4Z"
                            fill="var(--fontcolor)"
                        />
                    </svg>
                </button>
            </div>
        </div>
    );
}
